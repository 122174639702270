<template>
  <Layout>
    <div v-if="!sent" class="container">
      <div id="forgotForm" class="container is-fluid">
        <h1>That's Okay. Everyone Forgets.</h1>
        <div class="notification is-danger is-vcentered" v-if="generalError">
          {{ generalError }}
        </div>
        <label class="label">
          Just tell us the email address you used to create your account and
          we'll send you a new one!
        </label>
        <form
          id="registrationForm"
          class="mainFormContainer"
          @submit="submitForm($event)"
          novalidate
        >
          <div class="fieldContainer">
            <div class="field">
              <div class="control">
                <input
                  id="email"
                  name="email"
                  class="input"
                  :class="{ 'is-danger': !email.valid }"
                  type="email"
                  v-model="emailAddress"
                  placeholder="email address"
                  required="required"
                  @keyup="emitChange($event)"
                />
              </div>
              <p class="help" :class="{ 'is-danger': !email.valid }">
                {{ email.errorMessage }}
              </p>
            </div>
          </div>
          <button
            type="submit"
            class="button is-fullwidth is-primary text-capitalize"
          >
            Send Verification Email
          </button>
          <router-link :to="{ name: 'home' }" class="link"
            >Back to Log in</router-link
          >
        </form>
      </div>
    </div>
    <div v-else-if="sent" class="container">
      <div id="forgotFormNext" class="container is-fluid">
        <h1>Next Step! Please check your email.</h1>
        <div class="notification is-danger is-vcentered" v-if="generalError">
          {{ generalError }}
        </div>
        <label class="label"
          >We have received your password reset request.</label
        >
        <div class="divide">
          <label class="label">
            If your email is associated to a Forms account, we will be sending
            you an email to reset your password shortly.
          </label>
          <label class="label"
            >Please follow the instructions in the email to reset your
            password.</label
          >
        </div>
        <div class="divide">
          <label class="label">
            It's also possible that the email may arrive in your spam folder.
            Please note that this password reset link expire within 24 hours for
            security porpuses
          </label>
        </div>
        <router-link :to="{ name: 'login' }">
          <button class="button is-fullwidth is-primary">
            Return to Log in
          </button>
        </router-link>
      </div>
    </div>
  </Layout>
</template>

<script>
import axios from 'axios';

export default {
  name: 'forgotPassword',
  components: {
    Layout: () => import('@/router/layouts/Main')
  },
  props: {},
  data: function() {
    return {
      sent: false,
      emailAddress: '',
      generalError: '',
      email: {
        valid: true,
        errorMessage: ''
      }
    };
  },
  methods: {
    submitForm(evt) {
      evt.preventDefault();
      let form = this.$el.querySelector('#registrationForm');
      if (form.checkValidity()) {
        axios
          .post($formsConfig.core.api.reset, { email: this.emailAddress })
          .then(response => {
            if (response) {
              this.sent = true;
            }
          });
      }
    },
    validate(fieldObject) {
      let valid = fieldObject.field.checkValidity();
      fieldObject.validation.valid = valid;
      fieldObject.validation.errorMessage = fieldObject.field.validationMessage;
      return valid;
    },
    clearValidity(fieldObject) {
      fieldObject.validation.valid = true;
      fieldObject.validation.errorMessage = '';
    },
    emitChange(evt) {
      let field = evt.currentTarget;
      let fieldObject = {
        field: field,
        validation: this[field.attributes.name.value]
      };
      if (!this.validate(fieldObject)) {
        return;
      }
      this.clearValidity(fieldObject);
    }
  }
};
</script>

<style lang="scss" scoped>
.fieldContainer {
  @extend %fieldContainer;
}

.is-fluid {
  align-items: center;
  background: $background-panels;
  border-radius: $control-radius;
  h1 {
    margin-bottom: 30px;
    text-align: center;
  }
}

#forgotForm {
  padding: 50px 250px 50px 250px;
  margin: 75px 98px 0 92px;
  .label {
    font-weight: normal;
    text-align: center;
  }
}

#forgotFormNext {
  padding: 50px 250px 50px 250px;
  margin: 40px 98px 0 92px;
  .label {
    font-weight: normal;
    text-align: center;
  }
}

.divide {
  margin-bottom: 15px;
}
</style>
